<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="goBack">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Diário Online
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-btn
            color="success"
            @click="criarGestaoDeAula"
            class="mr-2"
            v-show="$can(['gestoesDeAulas.criar', 'gestoesDeAulas.*'])"
            >Criar Vínculo</v-btn
          >
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          >
          </v-text-field>
        </div>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="gestoesDeAulasList"
          :loading="carregandoGestoesDeAulas"
          :items-per-page="10"
          hide-default-footer
          no-data-text="Não foram encontrádos vínculos para você, por favor entre em contato com a secretaria/coordenação para que lhe adicionem acesso às turmas e disciplinas"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="
                  gestaoDeAulas.estaDeletado
                    ? ['deleted']
                    : parseInt(gestaoDeAulas.notifiq, 10) === 1
                    ? 'green lighten-5'
                    : ''
                "
                v-for="gestaoDeAulas of items"
                :key="gestaoDeAulas.id"
              >
                <td>{{ gestaoDeAulas.id }}</td>
                <td width="15%">
                  {{ gestaoDeAulas.nomeFranquia ? gestaoDeAulas.nomeFranquia : "- - -" }}
                </td>
                <td width="18%">
                  {{ gestaoDeAulas.nomeTurma ? gestaoDeAulas.nomeTurma : "- - -" }}
                  <v-chip
                    v-if="parseInt(gestaoDeAulas.notifiq, 10) === 1"
                    label
                    class="white--text"
                    color="green darken-4"
                    x-small
                  >
                    NOTIFIQ
                  </v-chip>
                </td>
                <td v-if="gestaoDeAulas.disciplinas.length">
                  <div v-for="(item, index) in gestaoDeAulas.disciplinas" :key="index">
                    <v-chip x-small>{{ item.descricao }} </v-chip>
                  </div>
                </td>
                <td v-else>
                  {{ gestaoDeAulas.nomeDisciplina ? gestaoDeAulas.nomeDisciplina : "- - -" }}
                </td>
                <td width="25%">
                  {{ gestaoDeAulas.nomeProfessor ? gestaoDeAulas.nomeProfessor : "- - -" }}
                </td>
                <td>
                  <e-resumo-aulas-snippet :aulas="gestaoDeAulas.aulas" />
                </td>
                <!-- <td>{{ gestaoDeAulas . contexto }}</td> -->
                <td width="7%">
                  <div class="text-center">
                    <v-menu :close-on-content-click="false" :nudge-width="100" offset-x>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn x-small color="indigo" dark v-bind="attrs" v-on="on"> AÇÕES </v-btn>
                      </template>

                      <v-card class="mx-auto" max-width="430">
                        <v-list>
                          <v-list-item>
                            <v-list-item-avatar color="primary">
                              <v-list-item-title
                                v-if="gestaoDeAulas.disciplinas.length"
                                style="color: #fff"
                              >
                                {{
                                  gestaoDeAulas.disciplinas.descricao
                                    ? gestaoDeAulas.disciplinas.descricao
                                        .substring(0, 1)
                                        .toUpperCase()
                                    : "P"
                                }}
                              </v-list-item-title>
                              <v-list-item-title v-else style="color: #fff">
                                {{
                                  gestaoDeAulas.disciplina?.descricao
                                    ? gestaoDeAulas.disciplina?.descricao
                                        .substring(0, 1)
                                        .toUpperCase()
                                    : "- - -"
                                }}
                              </v-list-item-title>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title>
                                {{
                                  gestaoDeAulas.nomeProfessor
                                    ? gestaoDeAulas.nomeProfessor
                                    : "- - -"
                                }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ gestaoDeAulas.nomeTurma ? gestaoDeAulas.nomeTurma : "- - -" }}
                                /
                                {{
                                  gestaoDeAulas.nomeDisciplina
                                    ? gestaoDeAulas.nomeDisciplina
                                    : "- - -"
                                }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                        <v-divider></v-divider>
                        <!-- item -->

                        <v-list>
                          <!-- <v-list-item-group
                            v-if="
                              !gestaoDeAulas.estaDeletado && !gestaoDeAulas.turma.isInfantil
                            "
                            color="primary"
                          >
                            <v-list-item
                              :to="{
                                name: 'gestoesDeAulas.diario',
                                params: { gestaoDeAulasId: gestaoDeAulas.id },
                              }"
                            >
                              <v-list-item-icon>
                                <v-icon small>fa fa-file-alt</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Dashboard</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group> -->
                          <!-- item -->
                          <v-list-item-group
                            v-if="!gestaoDeAulas.estaDeletado && !gestaoDeAulas.turma.isInfantil"
                            color="primary"
                          >
                            <v-list-item
                              v-if="gestaoDeAulas.is_polivalencia"
                              @click="
                                escolherDisciplina(
                                  gestaoDeAulas.id,
                                  gestaoDeAulas.disciplinas,
                                  'notas'
                                )
                              "
                            >
                              <v-list-item-icon>
                                <v-icon small>fa fa-file-alt</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Notas</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                              v-else
                              :to="{
                                name: 'notas.circuitoNotas',
                                params: {
                                  gestaoDeAulasId: gestaoDeAulas.id,
                                  disciplinaId: gestaoDeAulas.disciplina_id,
                                },
                              }"
                            >
                              <v-list-item-icon>
                                <v-icon small>fa fa-file-alt</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Notas</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                          <!-- item -->

                          <v-list-item-group color="primary">
                            <v-list-item
                              v-if="!gestaoDeAulas.estaDeletado && gestaoDeAulas.turma.isInfantil"
                              :to="{
                                name: 'relatorios.visualizar',
                                params: {
                                  turmaId: gestaoDeAulas.turma_id,
                                  gestaoDeAulasId: gestaoDeAulas.id,
                                },
                              }"
                            >
                              <v-list-item-icon>
                                <v-icon small>mdi-file-document</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{
                                    gestaoDeAulas.turma.curso.descricao.charAt(0).toUpperCase() +
                                    gestaoDeAulas.turma.curso.descricao.slice(1).toLowerCase()
                                  }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                          <!-- item -->

                          <!-- item -->
                          <v-list-item-group v-if="user.role.name != 'professor'" color="primary">
                            <v-list-item @click="() => listarAulas(gestaoDeAulas)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-check</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Confirmações de aulas </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                          <!-- item -->

                          <v-list-item-group color="primary">
                            <v-list-item @click="() => verAulas(gestaoDeAulas)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-eye</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Aulas</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                          <!-- item -->

                          <v-list-item-group
                            color="primary"
                            v-if="
                              !gestaoDeAulas.estaDeletado &&
                              $can(['gestoesDeAulas.atualizar', 'gestoesDeAulas.*'])
                            "
                          >
                            <v-list-item @click="() => editarGestaoDeAula(gestaoDeAulas)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-edit</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Editar gestão de aulas </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                          <!-- item -->

                          <v-list-item-group color="primary">
                            <v-list-item
                              v-if="!gestaoDeAulas.estaDeletado && !gestaoDeAulas.turma.isInfantil"
                              @click="
                                if (gestaoDeAulas.is_polivalencia === 1) {
                                  escolherDisciplina(
                                    gestaoDeAulas.id,
                                    gestaoDeAulas.disciplinas,
                                    'planos'
                                  );
                                } else {
                                  $router.push({
                                    name: 'gestoesDeAulas.planoBimestralCreate',
                                    params: {
                                      gestaoDeAulaId: gestaoDeAulas.id,
                                      disciplinaId: gestaoDeAulas.disciplina_id,
                                    },
                                  });
                                }
                              "
                            >
                              <v-list-item-icon>
                                <v-icon small>fa fa-book</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Criar Plano Bimestral</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                          <!-- item -->
                          <!-- <v-list-item
                            v-if="!gestaoDeAulas.estaDeletado && !gestaoDeAulas.turma.isInfantil"
                            @click="
                              if (gestaoDeAulas.is_polivalencia === 1) {
                                escolherDisciplina(
                                  gestaoDeAulas.id,
                                  gestaoDeAulas.disciplinas,
                                  'planosMensais'
                                );
                              } else {
                                $router.push({
                                  name: 'gestoesDeAulas.planoMensalCreate',
                                  params: {
                                    gestaoDeAulaId: gestaoDeAulas.id,
                                    disciplinaId: gestaoDeAulas.disciplina_id,
                                  },
                                });
                              }
                            "
                          >
                            <v-list-item-icon>
                              <v-icon small>fa fa-book</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Criar Plano Mensal</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item> -->
                          <!-- item -->

                          <v-list-item-group color="primary">
                            <v-list-item
                              v-if="!gestaoDeAulas.estaDeletado && gestaoDeAulas.turma.isInfantil"
                              :to="{
                                name: 'gestoesDeAulas.planoBimestralInfantil',
                                params: { gestaoDeAulaId: gestaoDeAulas.id },
                              }"
                            >
                              <v-list-item-icon>
                                <v-icon small>fa fa-book</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Plano bimestral Infantil</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>

                          <!-- item -->
                          <v-list-item-group>
                            <v-list-item
                              v-if="gestaoDeAulas.is_polivalencia === 1"
                              :to="{
                                name: 'gestoesDeAulas.metricasTurma.polivalencia',
                                params: {
                                  id: gestaoDeAulas.turma.id,
                                  gestao: gestaoDeAulas.id,
                                },
                              }"
                            >
                              <v-list-item-icon>
                                <v-icon small>mdi-chart-box</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Métricas da turma</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                              v-if="
                                gestaoDeAulas.is_polivalencia !== 1 &&
                                !gestaoDeAulas.turma.isInfantil
                              "
                              :to="{
                                name: 'gestoesDeAulas.metricasTurma',
                                params: {
                                  id: gestaoDeAulas.turma.id,
                                  gestao: gestaoDeAulas.id,
                                },
                              }"
                            >
                              <v-list-item-icon>
                                <v-icon small>mdi-chart-box</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Métricas da turma</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                          <!-- item -->

                          <!-- item -->
                          <v-list-item-group>
                            <v-list-item
                              v-if="gestaoDeAulas.turma.isInfantil"
                              :to="{
                                name: 'gestoesDeAulas.metricasTurma.infantil',
                                params: {
                                  id: gestaoDeAulas.turma.id,
                                  gestao: gestaoDeAulas.id,
                                },
                              }"
                            >
                              <v-list-item-icon>
                                <v-icon small>mdi-chart-box</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Métricas da turma infantil</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                          <!-- item -->

                          <v-list-item-group color="primary">
                            <v-list-item @click="capaDiarioClasse(gestaoDeAulas.id)">
                              <v-list-item-icon>
                                <v-icon small>mdi-book-open</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Capa de diário de classe </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>

                          <!-- Plano bimestral -->
                          <!-- <v-list-item-group color="primary">
                            <v-menu open-on-hover left offset-x>
                              <template v-slot:activator="{ on, attrs }">
                                <v-list-item
                                  v-if="
                                    !gestaoDeAulas.estaDeletado && !gestaoDeAulas.turma.isInfantil
                                  "
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="
                                    if (gestaoDeAulas.is_polivalencia === 1) {
                                      escolherDisciplinaMes(
                                        gestaoDeAulas.id,
                                        gestaoDeAulas.disciplinas
                                      );
                                    } else {
                                      escolherDisciplinaPDF(
                                        gestaoDeAulas.id,
                                        gestaoDeAulas.disciplina_id,
                                        'planosMensaisPDF'
                                      );
                                    }
                                  "
                                >
                                  <v-list-item-icon>
                                    <v-icon small>fa fa-file-pdf</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>Visualizar Plano Mensal </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-menu>
                          </v-list-item-group> -->
                          <!-- <v-list-item-group color="primary">
                            <v-menu open-on-hover left offset-x>
                              <template v-slot:activator="{ on, attrs }">
                                <v-list-item
                                  v-if="
                                    !gestaoDeAulas.estaDeletado && gestaoDeAulas.turma.isInfantil
                                  "
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="
                                    escolherDisciplinaPDF(
                                      gestaoDeAulas.id,
                                      gestaoDeAulas.disciplina_id,
                                      'planosMensaisPDF'
                                    )
                                  "
                                >
                                  <v-list-item-icon>
                                    <v-icon small>fa fa-file-pdf</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      >Visualizar Plano Mensal Infantil
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-menu>
                          </v-list-item-group> -->
                          <!-- Plano bimestral -->
                          <v-list-item-group color="primary">
                            <v-menu open-on-hover left offset-x>
                              <template v-slot:activator="{ on, attrs }">
                                <v-list-item
                                  v-if="
                                    !gestaoDeAulas.estaDeletado &&
                                    !gestaoDeAulas.turma.isInfantil &&
                                    gestaoDeAulas.is_polivalencia
                                  "
                                  @click="
                                    escolherDisciplinaPlanoBimestral(
                                      gestaoDeAulas.id,
                                      gestaoDeAulas.disciplinas
                                    )
                                  "
                                >
                                  <v-list-item-icon>
                                    <v-icon small>fa fa-file-pdf</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      >Visualizar Plano Bimestral-1
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                  v-else-if="!gestaoDeAulas.turma.isInfantil"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-list-item-icon>
                                    <v-icon small>fa fa-file-pdf</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      >Visualizar Plano Bimestral-2
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="(item, index) in $constants.bimestres"
                                  :key="index"
                                >
                                  <v-list-item-title
                                    @click="
                                      gerarPlanoBimestral(
                                        gestaoDeAulas.id,
                                        item,
                                        gestaoDeAulas.disciplina_id
                                      )
                                    "
                                  >
                                    {{ item.desc }}
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-list-item-group>

                          <!-- infantil -->
                          <v-list-item-group color="primary">
                            <v-menu open-on-hover left offset-x>
                              <template v-slot:activator="{ on, attrs }">
                                <v-list-item
                                  v-if="
                                    !gestaoDeAulas.estaDeletado && gestaoDeAulas.turma.isInfantil
                                  "
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-list-item-icon>
                                    <v-icon small>fa fa-file-pdf</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      >Gerar plano bimestral infantil</v-list-item-title
                                    >
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="(item, index) in $constants.bimestres"
                                  :key="index"
                                >
                                  <v-list-item-title
                                    @click="gerarPlanoBimestralInfantil(gestaoDeAulas.id, item)"
                                  >
                                    {{ item.desc }}
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-list-item-group>
                          <!-- infantil -->
                          <!-- <v-list-item-group color="primary">
                            <v-menu open-on-hover left offset-x>
                              <template v-slot:activator="{ on, attrs }">
                                <v-list-item
                                  v-if="
                                    !gestaoDeAulas.estaDeletado && gestaoDeAulas.turma.isInfantil
                                  "
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-list-item-icon>
                                    <v-icon small>fa fa-file-pdf</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      >Gerar plano bimestral infantil</v-list-item-title
                                    >
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="(item, index) in $constants.bimestres"
                                  :key="index"
                                >
                                  <v-list-item-title
                                    @click="gerarPlanoBimestralInfantil(gestaoDeAulas.id, item)"
                                  >
                                    {{ item.desc }}
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-list-item-group> -->

                          <!-- Plano bimestral infantil model antigo-->

                          <!-- <v-list-item-group color="primary">
                            <v-menu open-on-hover left offset-x>
                              <template v-slot:activator="{ on, attrs }">
                                <v-list-item
                                  v-if="
                                    !gestaoDeAulas.estaDeletado &&
                                    gestaoDeAulas.turma.isInfantil
                                  "
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-list-item-icon>
                                    <v-icon small>fa fa-file-pdf</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      >Gerar plano bimestral infantil (Modelo
                                      anterior)</v-list-item-title
                                    >
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="(item, index) in $constants.bimestres"
                                  :key="index"
                                >
                                  <v-list-item-title
                                    @click="gerarPlanoBimestral(gestaoDeAulas.id, item)"
                                  >
                                    {{ item.desc }}
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-list-item-group> -->

                          <!-- <v-list-item-group
                            color="primary"
                            v-if="
                              !gestaoDeAulas.estaDeletado && gestaoDeAulas.turma.isInfantil
                            "
                          >
                            <v-menu open-on-hover left offset-x>
                              <template v-slot:activator="{ on, attrs }">
                                <v-list-item v-bind="attrs" v-on="on">
                                  <v-list-item-icon>
                                    <v-icon small>fa fa-file-pdf</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      >Gerar plano bimestral infantil (Modelo
                                      anterior)</v-list-item-title
                                    >
                                  </v-list-item-content>
                                </v-list-item>
                              </template>

                              <v-list-item
                                v-for="(item, index) in $constants.bimestres"
                                :key="index"
                              >
                                <v-list-item-title
                                  @click="gerarPlanoBimestral(gestaoDeAulas.id, item)"
                                >
                                  {{ item . desc }}
                                </v-list-item-title>
                              </v-list-item>
                            </v-menu>
                          </v-list-item-group> -->
                          <!-- item -->

                          <v-list-item-group color="primary">
                            <v-list-item @click="() => recuperarGestaoDeAula(gestaoDeAulas)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-sync</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Recuperar gestão de aulas </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>

                          <!-- item -->
                          <v-list-item-group
                            v-if="$can(['gestoesDeAulas.deletar', 'gestoesDeAulas.*'])"
                            color="primary"
                          >
                            <v-list-item
                              v-if="user.role.id == 7 || user.role.id == 1"
                              @click="() => deletarGestaoDeAula(gestaoDeAulas)"
                            >
                              <v-list-item-icon>
                                <v-icon small>fa fa-trash</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Apagar gestão de aulas </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>

        <div v-if="dialogConfirmarAulas">
          <e-dialog-search-aulas-interval-date
            :gestao_selected_to_interval="gestao_selected_to_interval"
            :dialogConfirmarAulas="dialogConfirmarAulas"
            @dialogAulasChange="dialogConfirmarAulas = $event"
          >
          </e-dialog-search-aulas-interval-date>
        </div>

        <e-paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :pageNumber="pageNumber"
          @changeActionResponse="listarGestoesDeAulas"
          :search="search"
        >
        </e-paginate>
      </v-col>
    </v-row>
    <e-escolha-disciplina
      @status="abrirModalEscolha = $event"
      :dialog="abrirModalEscolha"
      :gestaoDeAulasId="gestaoDeAulasEscolhida"
      :disciplinas="disciplinasArray"
      :typeModel="typeModel"
    ></e-escolha-disciplina>
    <e-escolha-disciplina-mes
      @status="abrirModalEscolhaMes = $event"
      :dialog="abrirModalEscolhaMes"
      :gestaoDeAulasId="gestaoDeAulasEscolhida"
      :disciplinas="disciplinasArray"
      :typeModel="typeModel"
    ></e-escolha-disciplina-mes>
    <e-escolha-disciplina-mes-pdf
      @status="abrirModalEscolhaMesPDF = $event"
      :dialog="abrirModalEscolhaMesPDF"
      :gestaoDeAulasId="gestaoDeAulasEscolhida"
      :disciplinaId="disciplinaEscolhida"
      :typeModel="typeModel"
    ></e-escolha-disciplina-mes-pdf>
    <e-escolha-disciplina-plano-bimestral
      @status="abrirModalEscolhaPlanoBimestral = $event"
      :dialog="abrirModalEscolhaPlanoBimestral"
      :gestaoDeAulasId="gestaoDeAulasEscolhida"
      :disciplinas="disciplinasArray"
      :typeModel="typeModel"
    ></e-escolha-disciplina-plano-bimestral>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import GestaoDeAula from "@/Models/GestaoDeAula";

export default {
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  data() {
    return {
      abrirModalEscolhaPlanoBimestral: false,
      typeModel: null,
      search: "",
      gestoesDeAulasList: [],
      pageNumber: 0,
      carregandoGestoesDeAulas: false,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      gestao_selected_to_interval: null,
      dialogConfirmarAulas: false,
      abrirModalEscolha: false,
      abrirModalEscolhaMes: false,
      abrirModalEscolhaMesPDF: false,
      table: {
        headers: [
          {
            text: "#",
            value: "id",
          },
          {
            text: "Escola",
            value: "nomeFranquia",
          },
          {
            text: "Turma",
            value: "nomeTurma",
          },
          {
            text: "Disciplina(s)",
            value: "nomeDisciplina",
          },
          {
            text: "Professor",
            value: "nomeProfessor",
          },
          {
            text: "Aulas",
            value: "",
          },
          {
            text: "",
            value: "actions",
            sortable: false,
            width: "200px",
          },
        ],
      },
      disciplinasArray: [],
      gestaoDeAulasEscolhida: null,
      disciplinaEscolhida: null,
    };
  },
  async mounted() {
    this.$loaderService.open("Carregando gestoesDeAulas");
    try {
      await this.listarGestoesDeAulas(this.pageNumber);
    } catch (error) {
      this.$handleError(error);
    }
    this.$loaderService.close();
  },
  watch: {
    carregandoGestoesDeAulas(value) {
      if (value && this.gestoesDeAulasList.length === 0) {
        this.$loaderService.open("Carregando gestoesDeAulas");
      } else {
        this.$loaderService.close();
      }
    },
    async search(val) {
      if (val.length > 2) {
        await this.listarGestoesDeAulas(0, val);
      }
      if (!val) {
        await this.listarGestoesDeAulas(0);
      }
    },
    quantidades_confirmadas(val) {
      if (val < 100) {
        return;
      }
      if (this.quantidades_confirmadas >= 100) {
        clearInterval(this.interval);
        this.dialogProgressAulas = false;
        this.$toast.success("Aulas confirmadas com sucesso");
        return;
      }
      this.quantidades_confirmadas = 0;
      this.bufferValue = 10;
      this.startBuffer();
    },
  },
  methods: {
    escolherDisciplinaPlanoBimestral(gestaoDeAulasId, disciplinas, type) {
      this.abrirModalEscolhaPlanoBimestral = true;
      this.disciplinasArray = disciplinas;
      this.gestaoDeAulasEscolhida = gestaoDeAulasId;
      this.typeModel = type;
    },
    escolherDisciplina(idGestao, disciplinas, type) {
      this.abrirModalEscolha = true;
      this.disciplinasArray = disciplinas;
      this.gestaoDeAulasEscolhida = idGestao;
      this.typeModel = type;
    },
    escolherDisciplinaMes(idGestao, disciplinas, type) {
      this.abrirModalEscolhaMes = true;
      this.disciplinasArray = disciplinas;
      this.gestaoDeAulasEscolhida = idGestao;
      this.typeModel = type;
    },
    escolherDisciplinaPDF(idGestao, disciplina_id, type) {
      this.abrirModalEscolhaMesPDF = true;
      this.disciplinaEscolhida = disciplina_id;
      this.gestaoDeAulasEscolhida = idGestao;
      this.typeModel = type;
    },
    async listarAulas(gestaoDeAula) {
      this.gestao_selected_to_interval = gestaoDeAula;
      this.dialogConfirmarAulas = true;
    },

    async gerarPlanoBimestral(gestaoId, item, disciplinaId) {
      try {
        this.$loaderService.open("Gerando plano de aula...");
        await this.$services.relatoriosService.planoBimestral(
          parseInt(gestaoId, 10),
          parseInt(item.value, 10),
          parseInt(disciplinaId, 10)
        );
        this.$loaderService.close();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async gerarPlanoBimestralInfantil(gestaoId, item) {
      try {
        this.$loaderService.open("Gerando plano de aula...");
        await this.$services.relatoriosService.planoBimestralInfantil(
          parseInt(gestaoId, 10),
          parseInt(item.value, 10)
        );
        this.$loaderService.close();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async capaDiarioClasse(gestaoId) {
      this.$loaderService.open("Gerando capa diario...");
      try {
        await this.$services.relatoriosService.capaDiarioClasse(parseInt(gestaoId, 10));
        this.$loaderService.close();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    goBack() {
      if (this.user.role.name.includes("professor")) {
        this.$router.push({
          name: "acessoProfessor.home",
        });
      }
      this.$router.push({
        name: "home",
      });
    },
    async verAulas(gestaoDeAula) {
      this.$router.push({
        name: "aulas",
        params: {
          gestaoDeAulaId: gestaoDeAula.id,
        },
      });
    },
    async listarGestoesDeAulas(pageNumber, query = null) {
      this.carregandoGestoesDeAulas = true;
      try {
        const response = await this.$services.gestoesDeAulasService.syncAll(pageNumber, query);
        this.gestoesDeAulasList = await response.data.map((gestao) => new GestaoDeAula(gestao));
        this.paginate = response;
      } catch {
        this.carregandoGestoesDeAulas = false;
      }
      this.carregandoGestoesDeAulas = false;
    },
    criarGestaoDeAula() {
      this.$router.push({
        name: "gestoesDeAulas.create",
      });
    },
    editarGestaoDeAula(gestaoDeAulas) {
      this.$router.push({
        name: "gestoesDeAulas.edit",
        params: {
          gestaoDeAulasId: gestaoDeAulas.id,
        },
      });
    },
    async deletarGestaoDeAula(gestaoDeAulas) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta gestaoDeAulas?",
        confirmationCode: gestaoDeAulas.id,
        confirmationMessage: `Por favor, digite <strong>${gestaoDeAulas.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a GestaoDeAula");
              try {
                await this.$services.gestoesDeAulasService.deletarGestaoDeAula(gestaoDeAulas);
                this.$toast.success("GestaoDeAula deletada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    recuperarGestaoDeAula(gestaoDeAulas) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta gestaoDeAulas?",
        confirmationCode: gestaoDeAulas.id,
        confirmationMessage: `Por favor, digite <strong>${gestaoDeAulas.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a GestaoDeAula");
              try {
                await this.$services.gestoesDeAulasService.recuperarGestaoDeAula(gestaoDeAulas);
                this.$toast.success("GestaoDeAula recuperada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
  },
};
</script>

<style></style>
