import Axios from "@/plugins/Axios";

class OrgaoEmissorService {
  async listAll() {
    const response = await Axios().get("orgaoemissor");
    return response.data;
  }
}

export default new OrgaoEmissorService();
