import CircuitoNotasIndexPage from "@/Pages/CircuitoNotas/index.vue";
import NewEditCircuitoNotasPage from "@/Pages/CircuitoNotas/NewEditCircuitoNotasPage.vue";
import ShowCircuitoNotasPage from "@/Pages/CircuitoNotas/ShowCircuitoNotasPage.vue";
import CircuitoEtapasPage from "@/Pages/CircuitoNotas/CircuitoEtapasPage.vue";

export default [
  {
    path: "/circuito-notas/",
    name: "circuito-notas",
    component: CircuitoNotasIndexPage,
  },
  {
    path: "/circuito-notas/create",
    name: "circuito-notas.create",
    component: NewEditCircuitoNotasPage,
    props: { editing: false },
  },
  {
    path: "/circuito-notas/edit/:circuito_id",
    name: "circuito-notas.edit",
    component: NewEditCircuitoNotasPage,
    props: { editing: true },
  },
  {
    path: "/circuito-notas/etapas/:circuito_id",
    name: "circuito-notas.etapas",
    component: CircuitoEtapasPage,
    props: { editing: true },
  },
  {
    path: "/circuito-notas/show/:circuito_id",
    name: "circuito-notas.show",
    component: ShowCircuitoNotasPage,
    props: { editing: true },
  },
];
