import Axios from "@/plugins/Axios";

export class PlanosMensaisInfantil {
  async planoMensal(gestaoDeAulaId, mes_id) {
    const response = await Axios().get(
      `planos-mensais-infantil/plano-mensal/${gestaoDeAulaId}/${mes_id}`
    );
    return response.data;
  }

  async createOrUpdate(plano) {
    const response = await Axios().post(`planos-mensais-infantil/criar-plano-mensal`, {
      plano,
    });
    return response.data;
  }
}

export default new PlanosMensaisInfantil();
