<template>
  <div>
    <span>
      <v-menu v-if="user && $can(['aulas.mudarSituacao'])" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            label
            class="text-center"
            :color="mudarCor(descricaoSituacao)"
            x-small
            v-bind="attrs"
            v-on="on"
          >
            {{ descricaoSituacao }}
          </v-chip>
        </template>
        <v-list>
          <v-list-item
            v-for="situacao of $constants.situacaoAutorizacao"
            :key="situacao"
            link
            @click="() => changeSituacao(situacao)"
          >
            <v-list-item-title>{{ situacao }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-chip v-else label x-small>
        {{ descricaoSituacao }}
      </v-chip>
    </span>
    <v-progress-linear
      v-show="atualizando"
      indeterminate
      striped
      color="primary"
    ></v-progress-linear>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "situacao-autorizacao",
  props: {
    autorizacao: {
      type: Object,
      required: true,
    },
    i: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      atualizando: false,
      descricaoSituacao: this.autorizacao.status,
      dialog: false,
      motivo: null,
    };
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  methods: {
    mudarCor(status) {
      switch (status) {
        case "PENDENTE":
          return "success";
        case "APROVADO":
          return "green";
        case "CANCELADO":
          return "red";

        default:
          return "";
      }
    },
    async changeSituacao(novaSituacao) {
      this.atualizando = true;
      this.autorizacao.motivo = null;
      try {
        const aulaAtualizada = await this.$services.autorizacoesService.atualizarSituacao(
          this.autorizacao,
          novaSituacao
        );
        this.$emit("alterarSituacao", { aulaAtualizada, i: this.i });
        this.descricaoSituacao = aulaAtualizada.status;
      } catch (error) {
        this.$handleError(error);
      }
      this.atualizando = false;
    },
  },
};
</script>
