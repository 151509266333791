<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          v-if="plano.status"
          outlined
          label
          small
          :color="plano.status.color"
          dark
          v-bind="attrs"
          v-on="on"
        >
          {{ plano.status?.descricao ?? "Aguardando Confirmação" }}
        </v-chip>
        <v-chip
          v-else
          outlined
          label
          small
          :color="plano.color ? plano.status.color : 'orange'"
          dark
          v-bind="attrs"
          v-on="on"
        >
          {{ plano.status?.descricao ?? "Aguardando Confirmação" }}
        </v-chip>
      </template>
      <v-list v-if="permitirRolesParaGestores() >= 0">
        <v-list-item v-for="situacao of $constants.situacoesPlanos" :key="situacao.descricao" link>
          <v-list-item-title @click="() => atualizarSituacao(plano, situacao)">
            {{ situacao.descricao ? situacao.descricao : "- - -" }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["plano"],
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  methods: {
    async atualizarSituacao(plano, situacao) {
      const response = await this.$services.planosService.update(plano.id, situacao.id);
      if (response) {
        this.$emit("update", response);
      }
    },
    permitirRolesParaGestores() {
      try {
        // const userLocal = this.user.role ? this.user.role.name : [];
        // return this.$constants.rolesParaGestores.findIndex((elem) => {
        //   return elem.name === userLocal;
        // });
        const userLocal = this.user.roles ? this.user.roleName : [];
        return this.$constants.rolesParaGestores.findIndex((elem) => {
          return elem.name === userLocal;
        });
      } catch (error) {
        this.$handleError(error);
      }
      return false;
    },
  },
};
</script>
