import Model from "./Model";

export const SituacaoCircuitoType = {
  ATIVA: 1,
  DESATIVADA: 0,
};

export default class Circuito extends Model {
  constructor(franquia) {
    super();
    Object.assign(this, franquia);
  }

  get ativa() {
    return this.situacao === SituacaoCircuitoType.ATIVA;
  }

  get desativada() {
    return this.situacao === SituacaoCircuitoType.DESATIVADA;
  }
}
