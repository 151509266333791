import Geral from "@/Pages/GestaoDeAulasComplementar/Geral.vue";
import GestaoDeAulasIndexPage from "@/Pages/GestaoDeAulasComplementar/index.vue";
import NewEditGestaoDeAulaPage from "@/Pages/GestaoDeAulasComplementar/NewEditGestaoDeAulaPage.vue";
import NewEditGestaoDeAulaPageGeral from "@/Pages/GestaoDeAulasComplementar/NewEditGestaoDeAulaPageGeral.vue";

import MetricasDaTurma from "../Pages/GestaoDeAulasComplementar/dashboard.vue";
import Diario from "../Pages/GestaoDeAulasComplementar/Diario.vue";
import PlanoBimestralInfantil from "../Pages/GestaoDeAulasComplementar/planoBimestralInfantil.vue";
import PlanosBimestraisComplementares from "../Pages/GestaoDeAulasComplementar/planosBimestrais.vue";
import PlanoMensalInfantil from "../Pages/GestaoDeAulasComplementar/planoMensalInfantil.vue";
import PlanosMensais from "../Pages/GestaoDeAulasComplementar/planosMensais.vue";

export default [
  {
    path: "/gestoes-de-aulas-complementar/",
    name: "gestoesDeAulasComplementar",
    component: GestaoDeAulasIndexPage,
  },
  {
    path: "/gestoes-de-aulas-complementar/create",
    name: "gestoesDeAulasComplementar.create",
    component: NewEditGestaoDeAulaPage,
    props: { editing: false },
  },
  {
    path: "/gestoes-de-aulas-complementar/edit/:gestaoDeAulasId",
    name: "gestoesDeAulasComplementar.edit",
    component: NewEditGestaoDeAulaPage,
    props: { editing: true },
  },
  {
    path: "/gestoes-de-aulas-complementar/geral",
    name: "gestoesDeAulasComplementar.geral",
    component: Geral,
  },
  {
    path: "/gestoes-de-aulas-complementar/create",
    name: "gestoesDeAulasComplementar.createGeral",
    component: NewEditGestaoDeAulaPageGeral,
    props: { editing: false },
  },
  {
    path: "/gestoes-de-aulas-complementar/geral/edit/:gestaoDeAulasId",
    name: "gestoesDeAulasComplementar.editGeral",
    component: NewEditGestaoDeAulaPageGeral,
    props: { editing: true },
  },
  {
    path: "/gestoes-de-aulas-complementar/:gestaoDeAulaId/plano-bimestral/:disciplinaId/complementar",
    name: "gestoesDeAulasComplementar.planoBimestralCreate",
    component: PlanosBimestraisComplementares,
    props: { editing: false },
  },
  {
    path: "/gestoes-de-aulas-complementar/:gestaoDeAulaId/plano-bimestral-infantil",
    name: "gestoesDeAulasComplementar.planoBimestralInfantil",
    component: PlanoBimestralInfantil,
    props: { editing: false },
  },
  {
    path: "/gestoes-de-aulas-complementar/:id/turma/metricas/:gestao",
    name: "gestoesDeAulasComplementar.metricasTurma",
    component: MetricasDaTurma,
  },
  {
    path: "/gestoes-de-aulas-complementar/diario",
    name: "gestoesDeAulasComplementar.diario",
    component: Diario,
  },
  {
    path: "/gestoes-de-aulas-complementar/:gestaoDeAulaId/plano-mensal/:disciplinaId",
    name: "gestoesDeAulasComplementar.planoMensalCreate",
    component: PlanosMensais,
    props: { editing: false },
  },
  {
    path: "/gestoes-de-aulas-complementar/:gestaoDeAulaId/plano-mensal-infantil",
    name: "gestoesDeAulasComplementar.planoMensalInfantil",
    component: PlanoMensalInfantil,
    props: { editing: false },
  },
];
