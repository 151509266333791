<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Selecione um Mês </v-card-title>

        <v-card-text>
          <v-col class="d-flex" cols="12" sm="12">
            <v-select
              :items="$constants.meses"
              item-text="descricao"
              item-value="id"
              v-model="selectMonth"
              return-object
              label="Selecione um mês"
              solo
            ></v-select>
          </v-col>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="text" @click="$emit('dialogChange', false)"> Fechar </v-btn>
          <v-btn color="primary" text @click="tipoDiario"> Gerar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    gestao_id: {
      type: [Number, String],
      required: true,
    },
    tipo_diario: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectMonth: null,
    };
  },
  methods: {
    async tipoDiario() {
      if (this.tipo_diario === 1) {
        this.diarioFrequencia();
      }
      if (this.tipo_diario === 2) {
        this.diarioAulas();
      }
      if (this.tipo_diario === 3) {
        this.diarioFrequenciaComplementar();
      }
      if (this.tipo_diario === 4) {
        this.diarioAulasComplementares();
      }
    },
    async diarioFrequencia() {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.diarioFrequencia(this.gestao_id, this.selectMonth);
        this.$emit("dialogChange", !this.dialog);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async diarioFrequenciaComplementar() {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.diarioFrequenciaComplementar(
          this.gestao_id,
          this.selectMonth
        );
        this.$emit("dialogChange", !this.dialog);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async diarioAulas() {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.diarioAulas(this.gestao_id, this.selectMonth);
        this.$emit("dialogChange", !this.dialog);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async diarioAulasComplementares() {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.diarioAulasComplementares(
          this.gestao_id,
          this.selectMonth
        );
        this.$emit("dialogChange", !this.dialog);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style lang="scss" scoped></style>
