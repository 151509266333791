<template>
  <div class="d-flex align-center">
    <template>
      <v-chip
        label
        :color="value === 'REPROVADO' || value === 'TRANSFERIDO' ? 'error' : 'success'"
        small
      >
        {{ value }}
        <!-- <v-text-field
        :value="value"
        v-bind="{ outlined: false }"
        v-bind:style="{
          'min-width': '160px !important',
          'max-width': '160px !important',
        }"
        :disabled="true"
      /> -->
      </v-chip>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    matricula: {
      type: Object,
      required: true,
    },
    colorNota: {
      type: String,
    },
  },
  methods: {},
};
</script>
