<template>
  <div class="justify-center">
    <v-card style="text-align: center" class="border justify-center pa-10">
      <h5>Cabeçalho</h5>
    </v-card>
    <v-system-bar color="yellow lighten-5 mt-5">
      <p class="lineUp caption text--black mt-4">
        Organize a ordem das etapas e subetapas, arrastando e soltando.
      </p>
    </v-system-bar>
    <div class="container">
      <draggable v-model="etapas" group="etapas" class="text-center mt-5">
        <draggable class="row" v-model="etapas" group="etapas" @change="handleOrderChange">
          <v-card class="col dashed-border" v-for="(etapa, etapaIndex) in etapas" :key="etapaIndex">
            <h5>{{ etapa.descricao }}</h5>
            <v-icon small left>delete_forever</v-icon>
            <v-icon small left>add_circle</v-icon>
            <v-divider />
            <draggable
              class="row ma-3"
              v-model="etapa.subetapas"
              group="subetapas"
              @change="handleOrderChange"
            >
              <v-card
                class="col-3 dashed-border-menor"
                v-for="(subetapa, subetapaIndex) in etapa.subetapas"
                :key="subetapaIndex"
              >
                <p style="font-size: 10px; text-align: center">{{ subetapa.descricao }}</p>
                <v-icon small left>visibility</v-icon>
                <v-icon small left>visibility_off</v-icon>
                <v-icon small left>delete_forever</v-icon>
                <v-icon small left>add_circle</v-icon>
              </v-card>
            </draggable>
          </v-card>
        </draggable>
      </draggable>
    </div>
    <div class="container">
      <div class="row">
        <v-card style="text-align: center" class="border justify-center pa-10 mt-5 col-3">
          <h5>Disciplina</h5>
        </v-card>
        <v-card style="text-align: center" class="border justify-center pa-10 mt-5 col-9">
          <h5>Notas</h5>
        </v-card>
      </div>
    </div>
    <!-- <p class="lineUp caption text--black mt-4">Última Atualização: {{ layout }}</p> -->
  </div>
</template>

<style scoped>
.border::after {
  margin-left: 2px;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 4px solid #ccc;
  pointer-events: none;
}
.dashed-border::after {
  margin-left: 2px;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 4px dashed #ccc;
  pointer-events: none;
}
.dashed-border-menor::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px dashed #ccc;
  pointer-events: none;
}
</style>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  props: {
    circuitoId: {
      type: Number,
    },
    etapaCriadaOuAtualizada: {
      type: Object,
    },
    subetapaCriadaOuAtualizada: {
      type: Object,
    },
  },
  data() {
    return {
      etapas: [],
      etapasByCircuito: [],
      subEtapasByCircuito: [],
      layout: null,
    };
  },

  watch: {
    etapaCriadaOuAtualizada() {
      this.getEtapasByCircuitoNotaId();
    },
    subetapaCriadaOuAtualizada() {
      this.getEtapasByCircuitoNotaId();
    },
  },

  methods: {
    async getEtapasByCircuitoNotaId() {
      const resp = await this.$services.etapasService.getEtapasByCircuitoNotaId(this.circuitoId);
      this.etapasByCircuito = resp;
      this.etapas = resp;
      this.handleOrderChange();
    },

    async handleOrderChange() {
      // Lógica para tratar a alteração na ordem dos itens
      const array = JSON.stringify(this.etapas);
      const layout = await this.$services.circuitoNotasService.saveLayoutBoletim({
        circuito_nota_id: this.circuitoId,
        estrutura: array,
      });
    },
    async verifyLayoutExistente() {
      const data = await this.$services.circuitoNotasService.getLayoutBoletim(this.circuitoId);
      this.etapas = data;
    },
  },
  mounted() {
    this.verifyLayoutExistente();
  },
};
</script>
