<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'circuito-notas' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Circuito
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Descrição" rules="required" v-slot="{ errors }">
              <e-label>Descrição</e-label>
              <v-text-field
                v-on:change="(value) => deixarLetraMinuscula(value, 'descricao')"
                :error-messages="errors"
                v-model="formCircuito.descricao"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0">
            <ValidationProvider name="Descrição" rules="required" v-slot="{ errors }">
              <e-label>Tipo</e-label>

              <v-select
                :items="tipoCircuitos"
                :error-messages="errors"
                label="Selecione um tipo"
                v-model="formCircuito.tipo"
                :item-text="(subEt) => subEt.label"
                :item-value="(subEt) => subEt.value"
                outlined
              ></v-select>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              block
              >Enviar Dados</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<style></style>

<script>
import { mapActions, mapGetters } from "vuex";
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formCircuito() {
      if (!this.circuitos || !this.editing) return {};
      const { circuito_id } = this.$route.params;
      const circuito = this.circuitos.filter((cir) => cir.id === parseInt(circuito_id, 10)).shift();
      return { ...circuito };
    },
  },
  mounted() {
    this.loadCircuitos();
    this.loadDataCircuito();
  },
  data() {
    return {
      tipo: null,
      circuitoId: null,
      etapasByCircuito: [],
      submittingForm: false,
      circuitos: [],
      tipoCircuitos: [
        {
          label: "Notas em Letras",
          value: "letras",
        },
        {
          label: "Notas em Número",
          value: "numero",
        },
      ],
    };
  },
  methods: {
    async loadCircuitos() {
      try {
        const data = await this.$services.circuitoNotasService.syncAll();
        this.circuitos = data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    setCircuito(circuito) {
      this.circuitoId = circuito.id;
    },
    deixarLetraMinuscula(value, campo) {
      const novo_valor = value.toLowerCase().split(" ");
      const valor = novo_valor
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      this.formCircuito[campo] = valor;
    },
    async submitForm() {
      this.submittingForm = true;
      try {
        if (this.editing) {
          await this.$services.circuitoNotasService.atualizarCircuito(this.formCircuito);
        } else {
          await this.$services.circuitoNotasService.criarCircuito(this.formCircuito);
        }
        this.$toast.success("Circuito Atualizada com sucesso");
        this.$router.push({ name: "circuito-notas" });
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
    async loadDataCircuito() {
      try {
        if (this.circuitos.length === 0) {
          this.$loaderService.open("Carregando dados");
          await Promise.all([this.loadCircuitos()]);
          this.$loaderService.close();
        }
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style></style>
