import municipios from "./municipios";

export const $constants = {
  tiposDeMediacaoDidaticoPedagogica: ["Presencial", "Semipresencial", "Educação a distância – EAD"],
  localDeFuncionamentoDiferenciadoDaTurma: [
    "Sala anexa",
    "Unidade de atendimento socioeducativo",
    "Unidade prisional",
    "A turma não está em local de funcionamento diferenciado",
  ],
  tipoDeAtendimentoRealizado: [
    "Desenvolvimento de funções cognitivas",
    "Desenvolvimento de vida autônoma",
    "Enriquecimento curricular",
    "Ensino da informática acessível",
    "Ensino da língua brasileira de sinais",
    "Ensino da língua portuguesa como segunda língua",
    "Ensino de técnicas de orientação e mobilidade",
    "Ensino de comunicação alternativa e aumentativa",
    "Ensino do uso de recursos ópticos e não ópticos",
  ],
  aspectosObservados: [
    {
      descricao: "Habilidades trabalhadas",
      form: "horas_trabalhadas",
    },
    {
      descricao: "Descrição dos atendimentos/atividades",
      form: "descricao_atividades",
    },
    {
      descricao: "Avanços observados",
      form: "avancos_observados",
    },
    {
      descricao: "Dificuldades observadas",
      form: "dificuldades_observadas",
    },
    {
      descricao: "Frequência e participação nas atividades",
      form: "participacao_atividades",
    },
    {
      descricao: "Frequência e envolvimento da família",
      form: "envolvimento_da_familia",
    },
  ],
  diasDaSemana: [
    "Domingo",
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "Sexta-feira",
    "Sábado",
  ],
  tiposDeAtendimento: [
    "Escolarização",
    "Atendimento educacional especializado (AEE)",
    "Atividade complementar",
  ],
  modalidades: [
    "Ensino regular",
    "Educação especial - modalidade substitutiva",
    "Educação de jovens e adultos",
    "Educação profissional",
  ],

  situacoesMatricula: [
    "CURSANDO",
    // "APROVADO",
    "INATIVO",
    // "REPROVADO",
    "FALECIDO",
    "ABANDONO",
    "TRANSFERIDO",
    "CANCELADO",
    "TROCA DE TURMA",
    "TRANSFERIDO OUTROS",
    "RECEBIDA",
  ],

  situacoes: ["ATIVO", "INATIVO", "ENVIADO"],

  situacoesUsuarios: [
    {
      descricao: "ATIVO",
      cor: "success",
    },
    {
      descricao: "BLOQUEADO",
      cor: "error",
    },
  ],

  situacoesMatriculaCondition: {
    CURSANDO: false,
    INATIVO: true,
    FALECIDO: true,
    ABANDONO: true,
    TRANSFERIDO: true,
    CANCELADO: true,
    TROCA_DE_TURMA: true,
    TRANSFERIDO_OUTROS: true,
  },

  vinculo: [
    {
      desc: "Efetivo",
      value: 0,
    },
    {
      desc: "Celetista",
      value: 1,
    },
  ],

  bimestres: [
    {
      desc: "1° bimestre",
      value: 0,
    },
    {
      desc: "2° bimestre",
      value: 1,
    },
    {
      desc: "3° bimestre",
      value: 2,
    },
    {
      desc: "4° bimestre",
      value: 3,
    },
  ],

  bimestresBloqueio: [
    {
      desc: "Remover bloqueio",
      value: -1,
    },
    {
      desc: "1° bimestre",
      value: 0,
    },
    {
      desc: "2° bimestre",
      value: 1,
    },
    {
      desc: "3° bimestre",
      value: 2,
    },
    {
      desc: "4° bimestre",
      value: 3,
    },
  ],

  mesesBloqueio: [
    {
      desc: "Remover bloqueio",
      value: -1,
    },
    {
      desc: "Março",
      value: 0,
    },
    {
      desc: "Abril",
      value: 1,
    },
    {
      desc: "Maio",
      value: 2,
    },
    {
      desc: "Junho",
      value: 3,
    },
    {
      desc: "Julho",
      value: 4,
    },
    {
      desc: "Agosto",
      value: 5,
    },
    {
      desc: "Setembro",
      value: 6,
    },
    {
      desc: "Outubro",
      value: 7,
    },
    {
      desc: "Novembro",
      value: 8,
    },
  ],

  tipos_pesquisa_aluno: [
    {
      desc: "Pai",
      value: "filiacao2",
    },
    {
      desc: "Mãe",
      value: "filiacao1",
    },
    {
      desc: "Data de nasc",
      value: "data_nascimento",
    },
    {
      desc: "Gênero",
      value: "sexo_id",
    },
    {
      desc: "Cor/Raça",
      value: "cor_ou_raca",
    },
    {
      desc: "Nacionalidade",
      value: "naturalidade",
    },
    {
      desc: "País de nacionalidade",
      value: "nacionalidade",
    },
    {
      desc: "UF de nascimento",
      value: "estadualidade",
    },
  ],
  meses: [
    {
      descricao: "Janeiro",
      id: 1,
    },
    {
      descricao: "Fevereiro",
      id: 2,
    },
    {
      descricao: "Março",
      id: 3,
    },
    {
      descricao: "Abril",
      id: 4,
    },
    {
      descricao: "Maio",
      id: 5,
    },
    {
      descricao: "Junho",
      id: 6,
    },
    {
      descricao: "Julho",
      id: 7,
    },
    {
      descricao: "Agosto",
      id: 8,
    },
    {
      descricao: "Setembro",
      id: 9,
    },
    {
      descricao: "Outubro",
      id: 10,
    },
    {
      descricao: "Novembro",
      id: 11,
    },
    {
      descricao: "Dezembro",
      id: 12,
    },
  ],
  bimestresRendimentos: [
    {
      descricao: "1º BIMESTRE",
      id: 1,
    },
    {
      descricao: "2º BIMESTRE",
      id: 2,
    },
    {
      descricao: "RESULTADO DO 1º SEMESTRE",
      id: 3,
    },
    {
      descricao: "3º BIMESTRE",
      id: 4,
    },
    {
      descricao: "4º BIMESTRE",
      id: 5,
    },
    {
      descricao: "RESULTADO DO 2º SEMESTRE",
      id: 6,
    },
    {
      descricao: "RESULTADO FINAL",
      id: 7,
    },
    {
      descricao: "LISTA EM BRANCO",
      id: 8,
    },
  ],
  sistemaDeNotas: ["Numerica", "Relatório Infantil", "Relatório Individual"],
  atividadesComplementaresList: [
    {
      codigo: 11002,
      descricao: "Canto coral",
    },
    {
      codigo: 11006,
      descricao: "Banda",
    },
    {
      codigo: 11011,
      descricao: "Iniciação Musical",
    },
    {
      codigo: 12003,
      descricao: "Desenho",
    },
    {
      codigo: 12004,
      descricao: "Escultura e Cerâmica",
    },
    {
      codigo: 12005,
      descricao: "Grafite",
    },
    {
      codigo: 12007,
      descricao: "Pintura",
    },
    {
      codigo: 13001,
      descricao: "Cineclube",
    },
    {
      codigo: 14001,
      descricao: "Teatro",
    },
    {
      codigo: 14002,
      descricao: "Danças",
    },
    {
      codigo: 14004,
      descricao: "Práticas Circenses",
    },
    {
      codigo: 15001,
      descricao: "Capoeira",
    },
    {
      codigo: 15002,
      descricao: "Artesanato",
    },
    {
      codigo: 15003,
      descricao: "Brinquedos",
    },
    {
      codigo: 15004,
      descricao: "Contos",
    },
    {
      codigo: 16001,
      descricao: "Educação Patrimonial",
    },
    {
      codigo: 17004,
      descricao: "Leitura",
    },
    {
      codigo: 17002,
      descricao: "Línguas Estrangeiras",
    },
    {
      codigo: 19999,
      descricao: "Outra categoria de Cultura, Artes e Educação Patrimonial",
    },
    {
      codigo: 21001,
      descricao: "Recreação (Brinquedoteca e Jogos)",
    },
    {
      codigo: 22007,
      descricao: "Yoga",
    },
    {
      codigo: 22009,
      descricao: "Tênis de campo",
    },
    {
      codigo: 22011,
      descricao: "Atletismo",
    },
    {
      codigo: 22012,
      descricao: "Badminton",
    },
    {
      codigo: 22014,
      descricao: "Basquete",
    },
    {
      codigo: 22015,
      descricao: "Ciclismo",
    },
    {
      codigo: 22018,
      descricao: "Futebol",
    },
    {
      codigo: 22019,
      descricao: "Futsal",
    },
    {
      codigo: 22020,
      descricao: "Ginástica(rítmica, artística, acrobática)",
    },
    {
      codigo: 22021,
      descricao: "Handebol",
    },
    {
      codigo: 22022,
      descricao: "Judô",
    },
    {
      codigo: 22023,
      descricao: "Karatê",
    },
    {
      codigo: 22024,
      descricao: "Luta Olímpica",
    },
    {
      codigo: 22025,
      descricao: "Natação",
    },
    {
      codigo: 22026,
      descricao: "Taekwondo",
    },
    {
      codigo: 22027,
      descricao: "Tênis de Mesa",
    },
    {
      codigo: 22028,
      descricao: "Voleibol",
    },
    {
      codigo: 22029,
      descricao: "Vôlei de Praia",
    },
    {
      codigo: 22030,
      descricao: "Xadrez Tradicional/xadrez virtual",
    },
    {
      codigo: 29999,
      descricao: "Outra categoria de Esporte e Lazer",
    },
    {
      codigo: 22025,
      descricao: "Natação",
    },
    {
      codigo: 31002,
      descricao: "Português",
    },
    {
      codigo: 31001,
      descricao: "Matemática",
    },
    {
      codigo: 39999,
      descricao: "Outra categoria de Acompanhamento Pedagógico",
    },
    {
      codigo: 41007,
      descricao: "Educação em Direitos Humanos",
    },
    {
      codigo: 71007,
      descricao: "Promoção da Saúde",
    },
    {
      codigo: 10103,
      descricao: "Iniciação Científica",
    },
    {
      codigo: 13301,
      descricao: "Educação Ambiental e Desenvolvimento Sustentável",
    },
    {
      codigo: 13108,
      descricao: "Economia Solidária e Criativa/Educação Econômica (Educação Financeira e Fiscal)",
    },
    {
      codigo: 14101,
      descricao: "Fotografia",
    },
    {
      codigo: 14102,
      descricao: "História em Quadrinhos",
    },
    {
      codigo: 14103,
      descricao: "Jornal Escolar",
    },
    {
      codigo: 14104,
      descricao: "Rádio Escolar",
    },
    {
      codigo: 22025,
      descricao: "Vídeo",
    },
    {
      codigo: 14201,
      descricao: "Robótica Educacional",
    },
    {
      codigo: 14202,
      descricao: "Tecnologias Educacionais",
    },
    {
      codigo: 14203,
      descricao: "Ambientes de Redes Sociais",
    },
    {
      codigo: 14999,
      descricao: "Outra Categoria de Comunicação, Uso de Mídias e Cultura Digital e Tecnológica",
    },
    {
      codigo: 15101,
      descricao: "Memória e História das Comunidades Tradicionais",
    },
  ],
  sexoList: [
    {
      id: 1,
      descricao: "Masculino",
    },
    {
      id: 2,
      descricao: "Feminino",
    },
  ],
  corOuRacaList: ["Branca", "Preta", "Parda", "Amarela", "Indígena", "Não declarada"],
  nacionalidadeList: [
    "Brasileira",
    "Brasileira – nascido no exterior ou naturalizado",
    "Estrangeira",
  ],
  estadosList: [
    { codigo: "AC", descricao: "Acre" },
    { codigo: "AL", descricao: "Alagoas" },
    { codigo: "AP", descricao: "Amapá" },
    { codigo: "AM", descricao: "Amazonas" },
    { codigo: "BA", descricao: "Bahia" },
    { codigo: "CE", descricao: "Ceará" },
    { codigo: "DF", descricao: "Distrito Federal" },
    { codigo: "ES", descricao: "Espírito Santo" },
    { codigo: "GO", descricao: "Goías" },
    { codigo: "MA", descricao: "Maranhão" },
    { codigo: "MT", descricao: "Mato Grosso" },
    { codigo: "MS", descricao: "Mato Grosso do Sul" },
    { codigo: "MG", descricao: "Minas Gerais" },
    { codigo: "PA", descricao: "Pará" },
    { codigo: "PB", descricao: "Paraíba" },
    { codigo: "PR", descricao: "Paraná" },
    { codigo: "PE", descricao: "Pernambuco" },
    { codigo: "PI", descricao: "Piauí" },
    { codigo: "RJ", descricao: "Rio de Janeiro" },
    { codigo: "RN", descricao: "Rio Grande do Norte" },
    { codigo: "RS", descricao: "Rio Grande do Sul" },
    { codigo: "RO", descricao: "Rondônia" },
    { codigo: "RR", descricao: "Roraíma" },
    { codigo: "SC", descricao: "Santa Catarina" },
    { codigo: "SP", descricao: "São Paulo" },
    { codigo: "SE", descricao: "Sergipe" },
    { codigo: "TO", descricao: "Tocantins" },
  ],
  municipios,
  tipoDeDeficienciaList: [
    "Baixa visão",
    "Cegueira",
    "Deficiência auditiva",
    "Deficiência física",
    "Deficiência intelectual",
    "Deficiência Múltipla",
    "Epilepsia",
    "Síndrome de Down",
    "Surdez",
    "Surdocegueira",
    "TDAH (Transtorno do Déficit de Atenção e Hiperatividade)",
  ],
  tipoDeTranstornoDoEspectroAutistaList: ["Transtorno do espectro autista"],
  tipoDeAltasHabilidadesList: ["Altas habilidades/Superdotação"],
  recursosSaebList: [
    "Auxílio ledor",
    "Tradutor-intérprete de Libras",
    "Prova superampliada (Fonte 24)",
    "Auxílio transcrição",
    "Leitura labial",
    "CD com áudio para deficiente visual",
    "Guia-intérprete",
    "Prova ampliada (Fonte 18)",
    "Prova de Língua Portuguesa como Segunda Língua para surdos e deficientes auditivos",
    "Prova em Video Libras",
    "Material didático e prova em Braille",
    "Nenhum",
  ],
  zonaResidenciaList: ["Urbana", "Rural"],
  localizacaoDiferenciadaDeResidenciaList: [
    "Não está em área de localização diferenciada",
    "Área onde se localiza comunidade remanescente de quilombos",
    "Terra indígena",
    "Área de assentamento",
  ],
  rolesParaProfessores: [
    {
      id: "2",
      name: "professor",
    },
    {
      id: "9",
      name: "prof-auxiliar",
    },
    // {
    //   id: "5",
    //   name: "coordenador",
    // },
    // {
    //   id: "10",
    //   name: "coordenador semed",
    // },
    // {
    //   id: "7",
    //   name: "direção",
    // },
    // {
    //   id: "8",
    //   name: "supervisor",
    // },
    // {
    //   id: "1",
    //   name: "secretario",
    // },
    // {
    //   id: "3",
    //   name: "admin",
    // },
  ],
  rolesParaGestores: [
    {
      id: "1",
      name: "admin",
    },
    {
      id: "4",
      name: "secretario",
    },
    {
      id: "6",
      name: "super-admin",
    },
    {
      id: "9",
      name: "tec-admin",
    },
    {
      id: "7",
      name: "direção",
    },
    {
      id: "5",
      name: "coordenador",
    },
    {
      id: "10",
      name: "coordenador semed",
    },
  ],
  tiposDeAulas: [
    "Aula Remota",
    "Aula Normal",
    "Reposição",
    "Aula Extra",
    "Substituição",
    "Aula Antecipada",
    "Atividade Extra-classe",
    "Recuperação",
  ],
  situacoesPlanos: [
    {
      id: 1,
      descricao: "Plano Confirmado",
      cor: "green",
    },
    {
      id: 2,
      descricao: "Aguardando confirmação",
      cor: "orange",
    },
    {
      id: 3,
      descricao: "Plano Inválido",
      cor: "red",
    },
  ],
  // situacoesAulas: [
  //   {
  //     descricao: "Aula confirmada",
  //     cor: "success",
  //   },
  //   {
  //     descricao: "Aguardando confirmação",
  //     cor: "blue-grey lighten-4",
  //   },
  //   {
  //     descricao: "Aula rejeitada por falta",
  //     cor: "error",
  //   },
  //   {
  //     descricao: "Aula inválida",
  //     cor: "brown lighten-3",
  //   },
  //   {
  //     descricao: "Aula em conflito",
  //     cor: "warning",
  //   },
  // ],

  listCamposAulasLancadas: {
    conteudo: "Conteúdo",
    objetivos_ou_habilidades: "Objetivos ou Habilidades",
    metodologia: "Metodologia",
    // campos para a criação de aulas infantis
    campos_de_experiencias: "Campos de experiências",
    conteudo_polivalencia: "Conteúdo polivalência",
    eixos: "Eixos",
    estrategias: "Estrategias",
    recursos: "Recursos",
    atividade_classe: "Atividade de classe",
    atividade_casa: "Atividade de casa",
    observacoes: "Observações",
  },

  situacoesAulas: [
    {
      descricao: "Aula confirmada",
      cor: "success",
    },
    {
      descricao: "Aguardando confirmação",
      cor: "blue-grey lighten-4",
    },
    {
      descricao: "Aula rejeitada por falta",
      cor: "error",
    },
    {
      descricao: "Aula inválida",
      cor: "brown lighten-3",
    },
    {
      descricao: "Aula em conflito",
      cor: "warning",
    },
  ],
  situacoesAulasTabs: [
    {
      descricao: "Confirmadas",
      cor: "green lighten-4",
      tipo: "Aula confirmada",
    },
    {
      descricao: "Aguardando",
      cor: "blue-grey lighten-4",
      tipo: "Aguardando confirmação",
    },
    {
      descricao: "Rejeitadas",
      cor: "red lighten-4",
      tipo: "Aula rejeitada por falta",
    },
    {
      descricao: "Inválidas",
      cor: "brown lighten-5",
      tipo: "Aula inválida",
    },
    {
      descricao: "Conflitadas",
      cor: "amber lighten-4",
      tipo: "Aula em conflito",
    },
  ],
  situacoesAulasCor: {
    "Aula confirmada": "green lighten-2",
    "Aguardando confirmação": "blue-grey lighten-2",
    "Aula rejeitada por falta": "red lighten-2",
    "Aula inválida": "brown lighten-3",
    "Aula em conflito": "amber lighten-2",
  },
  situacoesAulasCorLight: {
    "Aula confirmada": "green lighten-4",
    "Aguardando confirmação": "blue-grey lighten-4",
    "Aula rejeitada por falta": "red lighten-4",
    "Aula inválida": "brown lighten-5",
    "Aula em conflito": "amber lighten-4",
  },
  subEtapaTipoCampos: [
    {
      descricao: "Valor (Inserir) - Número",
      slug: "valor",
    },
    {
      descricao: "Valor (Inserir) - Texto",
      slug: "valor_texto",
    },
    {
      descricao: "Resultado em número",
      slug: "resultado_em_lote",
    },
    {
      descricao: "Resultado em Texto",
      slug: "resultado_em_texto",
    },
  ],
  situacaoAutorizacao: ["PENDENTE", "RECUSADO", "APROVADO"],
  coresPrimarias: [
    {
      descricao: "Verde",
      codigo: "#00FF00",
    },
    {
      descricao: "Vermelho",
      codigo: "#FF0000",
    },
    {
      descricao: "Azul",
      codigo: "#0000FF",
    },
    {
      descricao: "Preto",
      codigo: "black",
    },
    {
      descricao: "Rosa",
      codigo: "pink",
    },
  ],
  optionsInformativo: [
    { title: "Restringir a um grupo" },
    { title: "Definir data para mostrar" },
    { title: "Definir data para encerrar" },
  ],

  mesesPlanoMensal: [
    {
      descricao: "Março",
      id: 0,
    },
    {
      descricao: "Abril",
      id: 1,
    },
    {
      descricao: "Maio",
      id: 2,
    },
    {
      descricao: "Junho",
      id: 3,
    },
    {
      descricao: "Julho",
      id: 4,
    },
    {
      descricao: "Agosto",
      id: 5,
    },
    {
      descricao: "Setembro",
      id: 6,
    },
    {
      descricao: "Outubro",
      id: 7,
    },
    {
      descricao: "Novembro",
      id: 8,
    },
  ],

  tipoFormacao: [
    { id: 1, label: "Ensino Médio" },
    { id: 2, label: "Curso Técnico" },
    { id: 3, label: "Tecnólogo" },
    { id: 4, label: "Licenciatura" },
    { id: 5, label: "Bacharelado" },
    { id: 6, label: "Graduação" },
    { id: 7, label: "Pós-graduação Lato Sensu (Especialização, MBA)" },
    { id: 8, label: "Pós-graduação Stricto Sensu (Mestrado)" },
    { id: 9, label: "Pós-graduação Stricto Sensu (Doutorado)" },
    { id: 10, label: "Pós-Doutorado" },
    { id: 11, label: "Outros" },
  ],
};

export default (vue) => {
  vue.prototype.$constants = $constants;
};
