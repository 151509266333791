<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'gestoesDeAulas.planosInfantil' })"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Plano Bimestral Infantil
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn depressed color="primary" @click="() => [visualizarPdf(plano.id), (dialog = true)]">
          Visualizar Pdf
          <v-icon small right> mdi-note </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" sm="10" md="12" lg="12">
        <v-card>
          <v-card-text>
            <label for="">Professor</label>
            <v-input>
              {{
                plano.gestao_de_aula
                  ? plano.gestao_de_aula?.professor
                    ? plano.gestao_de_aula?.professor?.nome
                    : "- - -"
                  : "- - - "
              }}
            </v-input>

            <label for="">Turma</label>
            <v-input>
              {{
                plano.gestao_de_aula
                  ? plano.gestao_de_aula.turma
                    ? plano.gestao_de_aula.turma.descricao
                    : "- - -"
                  : "- - - "
              }}
            </v-input>

            <label for="">Disciplina</label>
            <v-input>
              {{
                plano.gestao_de_aula
                  ? plano.gestao_de_aula.disciplina
                    ? plano.gestao_de_aula.disciplina.descricao
                    : "- - - "
                  : "- - - "
              }}
            </v-input>

            <label for="">Data lançamento</label>
            <v-input>
              <v-chip color="orange">
                {{
                  plano.created_at
                    ? plano.created_at.substr(0, 10).split("-").reverse().join("/")
                    : "- - - "
                }}
              </v-chip>
            </v-input>

            <label for="">Ultima atualização</label>
            <v-input>
              <v-chip color="orange">
                {{
                  plano.updated_at
                    ? plano.updated_at.substr(0, 10).split("-").reverse().join("/")
                    : "- - - "
                }}
              </v-chip>
            </v-input>
            <v-combobox
              class="custom-combobox"
              v-model="seriesSelecionadas"
              chips
              label="Series"
              multiple
              readonly
            />
          </v-card-text>
        </v-card>

        <v-card class="mt-5">
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="4">
                <label for="">Status</label>
                <e-situacao-plano-infantil :plano="plano" v-on:update="listarPlano" />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-divider></v-divider>
                <label>Objetivos de aprendizagem e desenvolvimento </label>
                <v-list-item v-for="(objetivo, i) in objetivosSelecionados" :key="i">
                  <li>
                    {{ objetivo ? objetivo.descricao : "- - -" }}
                  </li>
                </v-list-item>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-divider></v-divider>
                <label>Campos de experiência </label>
                <v-list-item v-for="(experiencia, i) in experienciasSelecionadas" :key="i">
                  <li>
                    {{ experiencia ? experiencia : "- - -" }}
                  </li>
                </v-list-item>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-divider></v-divider>
                <label for="">EIXOS TEMÁTICOS</label>
                <v-input color="primary">
                  {{ plano.eixos_tematicos ? plano.eixos_tematicos : "- - - " }}
                </v-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-divider></v-divider>

                <label for="">DIREITOS DE APRENDIZAGEM</label>
                <v-input>
                  {{ plano.direitos_aprendizagem ? plano.direitos_aprendizagem : "- - - " }}
                </v-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-divider></v-divider>
                <label for="">METODOLOGIA, RECURSOS E AVALIAÇÃO</label>
                <v-input filled rounded dense color="primary">
                  {{
                    plano.metodologia_recursos_avaliacao
                      ? plano.metodologia_recursos_avaliacao
                      : "- - -"
                  }}
                </v-input>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ModalCarteira :dialog="dialog" :pdf="pdf" />
    <EModalPDF :dialog="dialog" :pdf="pdf" />
  </main-template>
</template>

<script>
import EModalPDF from "@/components/EModalPDF.vue";

export default {
  components: {
    EModalPDF,
  },
  data() {
    return {
      dialog: false,
      pdf: null,
      showSnackbar: false,
      plano: {},
      plano_id: null,
      seriesSelecionadas: [],
      experienciasSelecionadas: [],
      objetivosSelecionados: [],
      recursosSelecionados: [],
      duvidasSelecionadas: [],
    };
  },
  async mounted() {
    this.getId();
    this.listarPlano(this.pageNumber);
  },
  methods: {
    async getId() {
      const { id } = this.$route.params;
      this.plano_id = id;
    },
    async listarPlano() {
      this.$loaderService.open("Carregando Plano");
      try {
        const { data } = await this.$services.planosService.showInfantil(this.plano_id);

        this.plano = { ...data.planoBimestralInfantil };
        this.seriesSelecionadas = this.plano.gestao_de_aula.turma.series.map((s) => s.descricao);

        if (this.dialog) {
          this.dialog = false;
        }

        this.objetivosSelecionados = [...data.objeto_aprendizagem];
        this.experienciasSelecionadas = [...data.planoBimestralInfantil.experiencia];
      } catch (error) {
        this.$loaderService.close();
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async carregarDadosAoAtualizarStatus() {
      try {
        this.listarPlano();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async visualizarPdf(planoId) {
      this.dialog = true;
      this.pdf = await this.$services.planosService.pdfInfantil(planoId);
    },
    fecharModal() {
      this.dialog = false;
    },
  },
};
</script>

<style>
.custom-combobox .v-chip {
  background-color: #f69300 !important;
  color: rgb(0, 0, 0) !important;
}
.custom-combobox .v-chip {
  background-color: #f69300 !important;
  color: rgb(0, 0, 0) !important;
}
</style>
