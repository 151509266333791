<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'matriculas' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Matricula
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row v-for="matricula of form.matriculas" :key="form.matriculas.indexOf(matricula)">
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Aluno</e-label>
            <e-autocomplete
              :items="alunosList"
              :return-object="false"
              :item-value="(aluno) => aluno.id"
              :item-text="
                (aluno) => {
                  return `${aluno.nomecompleto} (CPF: ${aluno.cpf ?? '- - -'})  `;
                }
              "
              :loading="carregandoAlunos"
              :readonly="editing"
              v-model="matricula.aluno_id"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Turma</e-label>
            <e-autocomplete
              :items="turmasList"
              :return-object="true"
              :item-text="(turma) => turma.descricao"
              :loading="carregandoTurmas"
              v-model="matricula.turma"
              @change="(turma) => changeTurma(matricula, turma)"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="3">
            <e-label>Ano</e-label>
            <e-autocomplete
              :items="matricula.turma ? matricula.turma.series : []"
              :return-object="false"
              :item-value="(serie) => serie.id"
              :item-text="(serie) => serie.descricao"
              :disabled="!matricula.turma"
              v-model="matricula.serie_id"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="1">
            <v-btn
              v-if="form.matriculas.length > 1"
              class="mt-8"
              color="error"
              @click="
                () =>
                  (form.matriculas = form.matriculas.filter(
                    (matriculaObj) => matriculaObj !== matricula
                  ))
              "
              small
              ><v-icon small>fa-trash</v-icon></v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="d-flex">
              <v-btn
                color="primary"
                :disabled="submittingForm"
                :loading="submittingForm"
                type="submit"
                >Enviar Dados</v-btn
              >
              <v-btn class="ml-3" v-if="!editing" color="secondary" @click="adicionarMatricula">
                Adicionar
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("Matriculas", ["matriculas", "carregandoMatriculas"]),
    // ...mapGetters("Alunos", ["alunosList", "carregandoAlunos"]),
    ...mapGetters("Turmas", ["turmasList", "carregandoTurmas"]),
  },
  mounted() {
    this.loadData();
    // this.loadFormData();
  },
  data() {
    return {
      submittingForm: false,
      form: { matriculas: [{}] },
      series: [],
      alunosList: [],
      carregandoAlunos: false,
    };
  },
  methods: {
    // ...mapActions("Alunos", ["loadAlunos"]),
    ...mapActions("Turmas", ["loadTurmas"]),
    changeTurma(matricula, turma) {
      matricula.turma_id = turma.id;
      matricula.serie_id = turma.series[0].id;
    },
    adicionarMatricula() {
      this.form.matriculas.push({});
    },
    async submitForm() {
      this.form.especial = Boolean(this.form.especial);
      this.form.recursos_saeb = this.recursos_saeb;
      this.submittingForm = true;
      try {
        if (this.editing) {
          await this.$services.matriculasService.atualizarMatricula(this.form.matriculas[0]);
          this.$toast.success("Matricula Atualizada com sucesso");
        } else {
          await this.$services.matriculasService.matricular(this.form);
          this.$toast.success("Matricula criada com sucesso");
        }
        this.$router.push({ name: "matriculas" });
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
    async loadAlunos() {
      this.carregandoAlunos = true;
      try {
        this.alunosList = await this.$services.alunosService.syncAll();
      } catch (error) {
        this.$handleError(error);
      }
      this.carregandoAlunos = false;
    },
    async loadData() {
      try {
        if (this.alunosList.length === 0 || this.turmasList.length === 0) {
          this.$loaderService.open("Carregando dados");
        }
        await this.loadFormData();

        await Promise.all([this.loadTurmas()]);
        this.$loaderService.close();
        this.$forceUpdate();
        this.loadAlunos();
        this.loadFormData();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async loadFormData() {
      if (!this.matriculas || !this.editing) {
        this.form = { matriculas: [{}] };
        return;
      }
      const { matricula_id } = this.$route.params;
      const matricula = await this.$services.matriculasService.getMatricula(matricula_id);

      if (matricula) this.form = { matriculas: [matricula] };
    },
  },
};
</script>

<style></style>
